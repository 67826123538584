@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

@layer base {
  body {
    @apply bg-dark text-white font-sans antialiased;
  }
}

@layer components {
  .section {
    @apply h-screen w-screen flex flex-col justify-center px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto overflow-hidden;
  }

  .heading {
    @apply text-4xl sm:text-5xl font-bold mb-6;
  }

  .subheading {
    @apply text-xl sm:text-2xl text-gray-400 mb-8;
  }

  .text-content {
    @apply text-lg text-gray-300 leading-relaxed;
  }

  .link {
    @apply text-primary hover:text-primary/80 transition-colors duration-200;
  }
}
